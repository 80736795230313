define("discourse/plugins/stemaway-project-generation/discourse/connectors/topic-navigation/ai-mentor", ["exports", "@ember/service", "@ember/component", "@ember/runloop", "discourse/lib/ajax"], function (_exports, _service, _component, _runloop, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    router: (0, _service.inject)(),
    evaluationService: (0, _service.inject)('ai-mentor-service'),
    currentUser: (0, _service.inject)(),
    chatVisible: false,
    messages: [],
    userInput: '',
    chatCollapsed: false,
    systemEvaluation: null,
    showEvaluationPopup: false,
    stemawayUser: null,
    isFinalEvaluation: false,
    init() {
      this._super(...arguments);
      this.evaluationService.checkIfTopicInCodeAlongCategory().then(() => {
        if (!this.get('evaluationService.displayCodeAlongButton')) {
          return;
        }
        this.topicId = this.get('router.currentRoute.parent.params.id');
        const chatVisible = localStorage.getItem('chatVisible') === 'true';
        const chatCollapsed = true;
        this.setProperties({
          chatVisible: chatVisible,
          chatCollapsed: chatCollapsed
        });
        if (this.get('currentUser')) {
          (0, _ajax.ajax)('/users/stemaway.json').then(response => {
            this.set('stemawayUser', response.user);
          });
        }
        this.checkGreeting();
      }).catch(error => {
        console.error('Error checking code along category:', error);
      });
    },
    checkGreeting() {
      this.scrollToBottom();
      if (this.evaluationService.firstGreeting) {
        let message = "Hello there! I am your AI Evaluator. How can I assist you today?<br><span class='italic-text-topics-mcq'>Type 'Evaluate' to get started.</span>";
        this.get('messages').pushObject({
          html: message,
          sender: 'ai',
          isHtml: true
        });
        this.evaluationService.setFirstGreeting(false);
      }
    },
    scrollToBottom() {
      (0, _runloop.scheduleOnce)('afterRender', this, function () {
        const chatMessagesContainer = document.querySelector('.chat-messages-topics-mcq');
        if (chatMessagesContainer) {
          chatMessagesContainer.lastElementChild.scrollIntoView({
            behavior: 'smooth'
          });
        }
      });
    },
    checkForEvaluation(message) {
      if (this.isEvaluateMode) {
        this.set('isLoadingResponse', true);
        (0, _ajax.ajax)('/stemaway-project-generation/check-user-query', {
          method: 'POST',
          data: {
            user_query: message
          }
        }).then(response => {
          if (response.response && response.evaluation) {
            this.get('messages').pushObject({
              text: response.response,
              sender: 'ai'
            });
            this.set('systemEvaluation', response.evaluation);
            if (response.response.includes("Final Evaluation Generated")) {
              this.set('isFinalEvaluation', true);
            }
          } else {
            this.get('messages').pushObject({
              text: "We had some issues on our end. Could you please try again?",
              sender: 'ai'
            });
          }
          this.scrollToBottom();
        }).catch(error => {
          console.error('Error checking user input for evaluation trigger:', error);
          this.get('messages').pushObject({
            text: "Error checking input.",
            sender: 'system'
          });
        }).finally(() => {
          this.set('isLoadingResponse', false);
        });
      }
    },
    actions: {
      startChat() {
        this.checkGreeting();
        this.set('chatVisible', true);
        this.set('chatCollapsed', false);
        localStorage.setItem('chatVisible', this.get('chatVisible'));
      },
      toggleCollapseChat() {
        this.checkGreeting();
        this.toggleProperty('chatCollapsed');
        localStorage.setItem('chatCollapsed', this.get('chatCollapsed'));
      },
      toggleChat() {
        this.checkGreeting();
        this.toggleProperty('chatVisible');
        localStorage.setItem('chatVisible', this.get('chatVisible'));
      },
      sendMessage() {
        let message = this.get('userInput').trim();
        if (!message) {
          return;
        }
        this.get('messages').pushObject({
          text: message,
          sender: 'user'
        });
        this.scrollToBottom();
        this.set('userInput', '');
        if (message.toLowerCase() === "evaluate") {
          this.set('isEvaluateMode', true);
          this.get('messages').pushObject({
            text: "Evaluation mode activated. Please proceed with your query.",
            sender: 'system'
          });
          this.scrollToBottom();
        } else {
          this.checkForEvaluation(message);
        }
      },
      handleTextareaKeyDown(event) {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault();
          this.send('sendMessage');
        }
      },
      toggleEvaluationPopup() {
        this.toggleProperty('showEvaluationPopup');
      },
      updateUserInput(value) {
        this.set('userInput', value);
      }
    }
  });
});